var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-form',{ref:"form",attrs:{"lazy-validation":""}},[_c('div',{staticClass:"d-flex justify-space-between my-5"},[_c('div',{staticClass:"v-text-field text-h4"},[_vm._v("Übersicht Testpersonen")]),_c('div',{staticClass:"v-text-field text-h6"},[_vm._v(_vm._s(_vm.organisation.name))])]),_c('v-card',[_c('v-card-title',[_c('v-dialog',{attrs:{"max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"color":"primary"}},'v-btn',attrs,false),on),[_vm._v(" Neue Testperson anlegen ")])]}}]),model:{value:(_vm.dialogNewWorker),callback:function ($$v) {_vm.dialogNewWorker=$$v},expression:"dialogNewWorker"}},[_c('v-card',{staticClass:"pb-5"},[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Neue Testperson anlegen")])]),_c('v-card-text',[_c('v-container',[_c('new-worker-form',{attrs:{"organisationId":_vm.organisation.id},on:{"newworker":_vm.newWorkerCreated}})],1)],1)],1)],1),_c('v-spacer'),_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary"},on:{"click":_vm.linkToImportView}},[_vm._v(" Testpersonen importieren ")])],1),_c('v-card-text',[_c('v-tabs',{attrs:{"background-color":"primary","dark":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tabs-slider',{attrs:{"color":"yellow"}}),_c('v-tab',[_vm._v("Testpersonen")]),_c('v-tab',[_vm._v("Durchgeführte Tests")]),_c('v-tab',[_vm._v("Nicht getesteste Pesonen")])],1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',[_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.workerSearch),callback:function ($$v) {_vm.workerSearch=$$v},expression:"workerSearch"}})],1),_c('v-data-table',{ref:"dataTable",staticClass:"elevation-1",attrs:{"headers":_vm.workerHeaders,"items":_vm.organisation.workers,"items-per-page":25,"search":_vm.workerSearch,"item-key":"id","sort-by":"id"},scopedSlots:_vm._u([{key:"item.checkin",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"small":"","outlined":"","color":"primary"},on:{"click":function($event){return _vm.newTest(item)}}},[_vm._v(" Check-in ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","depressed":"","color":"indigo"},on:{"click":function($event){return _vm.editWorker(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"small":"","color":"red"},on:{"click":function($event){return _vm.deleteWorker(item)}}},[_vm._v(" mdi-delete ")])]}},{key:"no-data",fn:function(){return undefined},proxy:true}])})],1),_c('v-tab-item',[_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.testSearch),callback:function ($$v) {_vm.testSearch=$$v},expression:"testSearch"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.testHeaders,"items":_vm.organisation.tests,"items-per-page":25,"search":_vm.testSearch,"item-key":"id","sort-by":"id","sort-desc":true},scopedSlots:_vm._u([{key:"item.testPerson",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getTestPersonName(item))+" ")]}},{key:"item.dateCheckedIn",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getDisplayDate(item.dateCheckedIn))+" ")]}}])})],1),_c('v-tab-item',[_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.notTestedSearch),callback:function ($$v) {_vm.notTestedSearch=$$v},expression:"notTestedSearch"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.notTestedHeaders,"items":_vm.notTestedWorkers,"items-per-page":25,"search":_vm.notTestedSearch,"item-key":"id","sort-by":"id","sort-desc":true}})],1)],1)],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDeleteWorker),callback:function ($$v) {_vm.dialogDeleteWorker=$$v},expression:"dialogDeleteWorker"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_c('div',[_vm._v("Möchten Sie die Testperson löschen?")])]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.dialogDeleteWorker = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.deleteWorkerConfirm}},[_vm._v("OK")])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"600px"},model:{value:(_vm.dialogEditWorker),callback:function ($$v) {_vm.dialogEditWorker=$$v},expression:"dialogEditWorker"}},[_c('edit-worker',{attrs:{"worker":_vm.organisation.workers[_vm.workerIndex]},on:{"editworker":_vm.workerEdited}})],1),_c('v-dialog',{attrs:{"max-width":"600px"},model:{value:(_vm.dialogNewTest),callback:function ($$v) {_vm.dialogNewTest=$$v},expression:"dialogNewTest"}},[_c('create-test',{attrs:{"workerId":_vm.workerId,"pharmacy":_vm.organisation.pharmacy,"orgId":_vm.organisation.id},on:{"newTest":_vm.newTestCreated}})],1),_c('v-snackbar',{attrs:{"timeout":"3000"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Schließen ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarText)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }