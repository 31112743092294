<template>
  <v-container>
    <v-form ref="form" lazy-validation>
      <div class="d-flex justify-space-between my-5">
        <div class="v-text-field text-h4">Übersicht Testpersonen</div>
        <div class="v-text-field text-h6">{{ organisation.name }}</div>
      </div>
      <v-card>
        <v-card-title>
          <v-dialog v-model="dialogNewWorker" max-width="600px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" class="mb-2" v-bind="attrs" v-on="on">
                Neue Testperson anlegen
              </v-btn>
            </template>
            <v-card class="pb-5">
              <v-card-title>
                <span class="headline">Neue Testperson anlegen</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <new-worker-form
                    :organisationId="organisation.id"
                    v-on:newworker="newWorkerCreated"
                  ></new-worker-form>
                </v-container>
              </v-card-text>
            </v-card>
          </v-dialog>
          <v-spacer></v-spacer>
          <v-btn color="primary" class="mb-2" @click="linkToImportView">
            Testpersonen importieren
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-tabs v-model="tab" background-color="primary" dark>
            <v-tabs-slider color="yellow"></v-tabs-slider>
            <v-tab>Testpersonen</v-tab>
            <v-tab>Durchgeführte Tests</v-tab>
            <v-tab>Nicht getesteste Pesonen</v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item>
              <v-card-title>
                <v-text-field
                  v-model="workerSearch"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field>
              </v-card-title>
              <v-data-table
                :headers="workerHeaders"
                :items="organisation.workers"
                :items-per-page="25"
                :search="workerSearch"
                item-key="id"
                ref="dataTable"
                sort-by="id"
                class="elevation-1"
              >
                <template v-slot:item.checkin="{ item }">
                  <v-btn class="mr-2" small outlined color="primary" @click="newTest(item)">
                    Check-in
                  </v-btn>
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-icon small depressed color="indigo" class="mr-2" @click="editWorker(item)">
                    mdi-pencil
                  </v-icon>
                  <v-icon @click="deleteWorker(item)" small color="red">
                    mdi-delete
                  </v-icon>
                </template>
                <template v-slot:no-data> </template>
              </v-data-table>
            </v-tab-item>
            <v-tab-item>
              <v-card-title>
                <v-text-field
                  v-model="testSearch"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field>
              </v-card-title>
              <v-data-table
                :headers="testHeaders"
                :items="organisation.tests"
                :items-per-page="25"
                :search="testSearch"
                item-key="id"
                sort-by="id"
                :sort-desc="true"
                class="elevation-1"
              >
                <template v-slot:item.testPerson="{ item }">
                  {{ getTestPersonName(item) }}
                </template>

                <template v-slot:item.dateCheckedIn="{ item }">
                  {{ getDisplayDate(item.dateCheckedIn) }}
                </template>
              </v-data-table>
            </v-tab-item>
            <v-tab-item>
              <v-card-title>
                <v-text-field
                  v-model="notTestedSearch"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field>
              </v-card-title>
              <v-data-table
                :headers="notTestedHeaders"
                :items="notTestedWorkers"
                :items-per-page="25"
                :search="notTestedSearch"
                item-key="id"
                sort-by="id"
                :sort-desc="true"
                class="elevation-1"
              >
              </v-data-table>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
      </v-card>
    </v-form>

    <v-dialog v-model="dialogDeleteWorker" max-width="500px">
      <v-card>
        <v-card-title class="headline"><div>Möchten Sie die Testperson löschen?</div></v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogDeleteWorker = false">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="deleteWorkerConfirm">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogEditWorker" max-width="600px">
      <edit-worker :worker="organisation.workers[workerIndex]" v-on:editworker="workerEdited"></edit-worker>
    </v-dialog>

    <v-dialog v-model="dialogNewTest" max-width="600px">
      <create-test
        :workerId="workerId"
        :pharmacy="organisation.pharmacy"
        :orgId="organisation.id"
        v-on:newTest="newTestCreated"
      ></create-test>
    </v-dialog>

    <v-snackbar v-model="snackbar" timeout="3000">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="primary" text v-bind="attrs" @click="snackbar = false">
          Schließen
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import NewWorkerForm from "../components/worker/New.vue";
import EditWorker from "../components/worker/Edit.vue";
import CreateTest from "../components/test/OrganisationCreateTest.vue";

import dayjs from "dayjs";
import lodash from "lodash";

export default {
  components: {
    NewWorkerForm,
    EditWorker,
    CreateTest,
  },
  data: () => ({
    tab: null,
    workerSearch: "",
    testSearch: "",
    notTestedSearch: "",
    organisation: { workers: [] },
    snackbar: false,
    snackbarText: "",
    workerIndex: Number,
    workerId: Number,
    workerDelete: {},
    dialogNewWorker: false,
    dialogDeleteWorker: false,
    dialogEditWorker: false,
    dialogNewTest: false,
    notTestedWorkers: {},
    workerHeaders: [
      {
        text: "ID",
        align: "start",
        value: "id",
      },
      { text: "Name", value: "fullName" },
      { text: "Check-in", value: "checkin", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    testHeaders: [
      {
        text: "ID",
        align: "start",
        value: "id",
      },
      { text: "Test Identifier", value: "uniqueIdentifierTest" },
      { text: "Testperson", value: "worker.fullName" },
      { text: "Datum Check-in", value: "dateCheckedIn", sortable: false },
    ],
    notTestedHeaders: [
      {
        text: "ID",
        align: "start",
        value: "id",
      },
      { text: "Name", value: "fullName" },
    ],
  }),

  mounted() {
    this.loading = true;
    this.$http({
      method: "get",
      url: "/organisation/" + this.$route.params.id,
    })
      .then((response) => {
        this.organisation = response.data;
        let tests = response.data.tests;
        let workers = response.data.workers;

        this.notTestedWorkers = workers.filter(function(worker) {
          return !tests.some(function(test) {
            return worker.id == test.worker;
          });
        });

        this.loading = false;
      })
      .catch((err) => {
        this.errored = true;
        this.loading = false;
        console.log("AXIOS ERROR: ", err);
      });
  },
  methods: {
    linkToImportView() {
      this.$router.push("/organisation/" + this.organisation.id + "/import");
    },

    newWorkerCreated(data) {
      this.dialogNewWorker = false;
      this.organisation.workers.push(data);
      this.snackbarText = "Testperson wurde erfolgreich angelegt";
      this.snackbar = true;
    },
    editWorker(item) {
      this.workerIndex = this.organisation.workers.indexOf(item);
      this.dialogEditWorker = true;
    },
    workerEdited() {
      this.dialogEditWorker = false;
      this.snackbarText = "Testperson wurde erfolgreich geändert";
      this.snackbar = true;
    },
    deleteWorker(item) {
      this.workerIndex = this.organisation.workers.indexOf(item);
      this.workerDelete = item;
      this.dialogDeleteWorker = true;
    },
    deleteWorkerConfirm() {
      this.$http({
        method: "delete",
        url: "/worker",
        data: {
          workerId: this.workerDelete.id,
          org: this.organisation.id,
        },
      })
        .then(() => {
          this.snackbarText = "Testperson erfolgreich gelöscht";
          this.snackbar = true;
          this.organisation.workers.splice(this.workerIndex, 1);
        })
        .catch((err) => {
          this.snackbarText = "Fehler bei Abfrage";
          this.snackbar = true;
          console.log("AXIOS ERROR: ", err);
        });

      this.dialogDeleteWorker = false;
    },
    newTest(item) {
      this.workerId = item.id;
      this.dialogNewTest = true;
    },
    newTestCreated(data) {
      this.dialogNewTest = false;
      this.organisation.tests.push({
        createdAt: data.createdAt,
        dateCheckedIn: data.dateCheckedIn,
        dateNotified: data.dateNotified,
        id: data.id,
        org: data.org.id,
        testType: data.testType,
        uniqueIdentifierTest: data.uniqueIdentifierTest,
        worker: data.worker.id,
      });
      this.snackbarText = "Testperson wurde erfolgreich eingecheckt";
      this.snackbar = true;
    },
    getTestPersonName(item) {
      var worker = lodash.find(this.organisation.workers, { id: item.worker });
      if (!worker) {
        return "Gelöscht";
      }
      return worker.fullName;
    },
    getDisplayDate(timestamp) {
      return dayjs(timestamp).format("DD.MM.YYYY");
    },
  },
};
</script>
