<template>
  <v-card>
    <v-card-title>
      <span class="headline">Testperson aktualisieren</span>
    </v-card-title>

    <v-card-text>
      <v-container>
        <v-form ref="userForm" v-model="editWorkerForm" lazy-validation>
          <v-row>
            <v-col class="d-flex" cols="12" lg="3">
              <v-select
                v-model="updatedWorker.gender"
                :items="titles"
                item-text="text"
                item-value="value"
                class="pt-6"
                :rules="[() => !!updatedWorker.gender || 'Dieses Feld wird benötigt!']"
                label="Anrede"
              ></v-select>
            </v-col>
            <v-col class="d-flex" cols="12" lg="9">
              <v-text-field
                class="pt-6"
                v-model="updatedWorker.fullName"
                label="Name"
                :rules="[() => !!updatedWorker.fullName || 'Dieses Feld wird benötigt!']"
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model="updatedWorker.birthdayDay"
                :rules="[() => !!updatedWorker.birthdayDay || 'Dieses Feld wird benötigt!']"
                label="Tag"
                required
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="updatedWorker.birthdayMonth"
                :rules="[() => !!updatedWorker.birthdayMonth || 'Dieses Feld wird benötigt!']"
                label="Monat"
                required
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="updatedWorker.birthdayYear"
                :rules="[() => !!updatedWorker.birthdayYear || 'Dieses Feld wird benötigt!']"
                required
                label="Jahr"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field v-model="updatedWorker.emailAddress" label="E-Mail Adresse"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="8">
              <v-text-field
                v-model="updatedWorker.streetName"
                :rules="[() => !!updatedWorker.streetName || 'Dieses Feld wird benötigt!']"
                required
                label="Straße"
              >
              </v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="updatedWorker.houseNumber"
                :rules="[() => !!updatedWorker.houseNumber || 'Dieses Feld wird benötigt!']"
                required
                label="Hausnummer"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model="updatedWorker.zipCode"
                :rules="[() => !!updatedWorker.zipCode || 'Dieses Feld wird benötigt!']"
                required
                label="Postleitzahl"
              >
              </v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="updatedWorker.cityName"
                :rules="[() => !!updatedWorker.cityName || 'Dieses Feld wird benötigt!']"
                required
                label="Stadt"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col><v-text-field v-model="updatedWorker.phoneNumber" label="Telefonnummer"></v-text-field> </v-col>
            <v-col><v-text-field v-model="updatedWorker.identityNumber" label="Ausweisnummer"></v-text-field> </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="blue darken-1" text @click="updateWorker()">Testperson aktualisieren</v-btn>
    </v-card-actions>
    <v-snackbar v-model="snackbar" timeout="3000">
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="primary" text v-bind="attrs" @click="snackbar = false">
          Schließen
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
const dayjs = require("dayjs");

export default {
  props: { worker: Object },
  data() {
    return {
      snackbar: false,
      snackbarText: "",
      titles: [
        { text: "Herr", value: "m" },
        { text: "Frau", value: "f" },
        { text: "Divers", value: "d" },
      ],
      editWorkerForm: false,
      updatedWorker: null,
    };
  },
  created() {
    if (this.updatedWorker == null) {
      this.updatedWorker = this.worker;
      var birthday = dayjs(this.worker.birthday);
      this.updatedWorker.birthdayDay = birthday.date();
      this.updatedWorker.birthdayMonth = birthday.month() + 1;
      this.updatedWorker.birthdayYear = birthday.year();
    }
  },
  watch: {
    worker: function(val) {
      if (val) {
        this.updatedWorker = this.worker;
        var birthday = dayjs(this.worker.birthday);
        this.updatedWorker.birthdayDay = birthday.date();
        this.updatedWorker.birthdayMonth = birthday.month() + 1;
        this.updatedWorker.birthdayYear = birthday.year();
      }
    },
  },
  methods: {
    updateWorker() {
      var birthday = new Date(
        this.updatedWorker.birthdayYear,
        this.updatedWorker.birthdayMonth - 1,
        this.updatedWorker.birthdayDay
      );

      if (this.$refs.userForm.validate()) {
        this.$http({
          method: "patch",
          url: "/worker/" + this.updatedWorker.id,
          data: {
            gender: this.updatedWorker.gender,
            fullName: this.updatedWorker.fullName,
            birthday: birthday.getTime(),
            emailAddress: this.updatedWorker.emailAddress.trim(),
            streetName: this.updatedWorker.streetName,
            houseNumber: this.updatedWorker.houseNumber,
            zipCode: this.updatedWorker.zipCode,
            cityName: this.updatedWorker.cityName,
            phoneNumber: this.updatedWorker.phoneNumber,
            org: this.worker.org,
            identityNumber: this.updatedWorker.identityNumber,
          },
        })
          .then((res) => {
            this.$emit("editworker", res.data);
          })
          .catch((err) => {
            this.snackbarText = "Fehler bei Abfrage";
            this.snackbar = true;
            console.log("AXIOS ERROR: ", err);
          });
      }
    },
  },
};
</script>
