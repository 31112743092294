<template>
  <v-card>
    <v-card-title>
      <span class="headline">Neuen Test anlegen</span>
    </v-card-title>

    <v-card-text>
      <v-container>
        <v-form ref="userForm" v-model="newTestForm" lazy-validation>
          <v-row>
            <v-col>
              <v-text-field
                class="pt-6"
                v-model="newTestFormData.uniqueIdentifierTest"
                label="Bitte 10 stellige Labornummern eingeben"
                :rules="[() => !!newTestFormData.uniqueIdentifierTest || 'Dieses Feld wird benötigt!']"
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="date"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date"
                    label="Check-in Datum wählen"
                    append-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="date" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu = false">
                    Cancel
                  </v-btn>
                  <v-btn text color="primary" @click="$refs.menu.save(date)">
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn :disabled="!newTestForm" color="blue darken-1" text @click="createTest()">Testperson einchecken</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: { workerId: Number, orgId: Number },
  data() {
    return {
      date: new Date().toISOString().substr(0, 10),
      menu: false,
      newTestForm: false,
      newTestFormData: {},
      worker: {},
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
  methods: {
    createTest() {
      if (this.$refs.userForm.validate()) {
        this.$http({
          method: "post",
          url: "test",
          data: {
            uniqueIdentifierTest: this.newTestFormData.uniqueIdentifierTest,
            dateCheckedIn: Date.parse(this.date),
            testType: 1,
            language: "de",
            org: this.orgId,
            worker: this.workerId,
          },
        })
          .then((res) => {
            this.$emit("newTest", res.data);
            this.newTestFormData = {};
          })
          .catch((err) => {
            console.log("AXIOS ERROR: ", err);
          });
      }
    },
  },
};
</script>
