<template>
  <v-form ref="newWorkerForm" v-model="newWorkerForm" lazy-validation>
    <v-row>
      <v-col sm="4">
        <v-select
          class="pt-6"
          :items="titles"
          ref="anrede"
          v-model="newWorkerFormData.gender"
          :rules="[() => !!newWorkerFormData.gender || 'Dieses Feld wird benötigt!']"
          label="Anrede"
        ></v-select>
      </v-col>
      <v-col>
        <v-text-field
          class="pt-6"
          v-model="newWorkerFormData.fullName"
          label="Name"
          :rules="[() => !!newWorkerFormData.fullName || 'Dieses Feld wird benötigt!']"
          required
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row class="mt-n6">
      <v-col>
        <v-text-field
          v-model="newWorkerFormData.birthdayDay"
          :rules="[() => !!newWorkerFormData.birthdayDay || 'Dieses Feld wird benötigt!']"
          label="Tag"
          required
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          v-model="newWorkerFormData.birthdayMonth"
          :rules="[() => !!newWorkerFormData.birthdayMonth || 'Dieses Feld wird benötigt!']"
          label="Monat"
          required
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          v-model="newWorkerFormData.birthdayYear"
          :rules="[() => !!newWorkerFormData.birthdayYear || 'Dieses Feld wird benötigt!']"
          required
          label="Jahr"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row class="mt-n6">
      <v-col><v-text-field v-model="newWorkerFormData.emailAddress" label="E-Mail Adresse"></v-text-field> </v-col>
    </v-row>
    <v-row class="mt-n6">
      <v-col sm="8">
        <v-text-field
          v-model="newWorkerFormData.streetName"
          :rules="[() => !!newWorkerFormData.streetName || 'Dieses Feld wird benötigt!']"
          required
          label="Straße"
        >
        </v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          v-model="newWorkerFormData.houseNumber"
          :rules="[() => !!newWorkerFormData.houseNumber || 'Dieses Feld wird benötigt!']"
          required
          label="Hausnummer"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row class="mt-n6">
      <v-col>
        <v-text-field
          v-model="newWorkerFormData.zipCode"
          :rules="[() => !!newWorkerFormData.zipCode || 'Dieses Feld wird benötigt!']"
          required
          label="Postleitzahl"
        >
        </v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          v-model="newWorkerFormData.cityName"
          :rules="[() => !!newWorkerFormData.cityName || 'Dieses Feld wird benötigt!']"
          required
          label="Stadt"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row class="mt-n6">
      <v-col><v-text-field v-model="newWorkerFormData.phoneNumber" label="Telefonnummer"></v-text-field> </v-col>
      <v-col><v-text-field v-model="newWorkerFormData.identityNumber" label="Ausweisnummer"></v-text-field> </v-col>
    </v-row>
    <v-btn
      :disabled="!newWorkerForm"
      :loading="workerLoading"
      color="primary light"
      @click="saveWorker"
      class="float-right"
    >
      Speichern
    </v-btn>
    <v-snackbar v-model="snackbar" timeout="3000">
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="primary" text v-bind="attrs" @click="snackbar = false">
          Schließen
        </v-btn>
      </template>
    </v-snackbar>
  </v-form>
</template>

<script>
export default {
  props: { organisationId: Number },
  data() {
    return {
      snackbar: false,
      snackbarText: "",
      titles: [
        { text: "Herr", value: "m" },
        { text: "Frau", value: "f" },
        { text: "Divers", value: "d" },
      ],
      newWorkerForm: false,
      newWorkerFormData: {},
      workerLoading: false,
    };
  },

  methods: {
    saveWorker() {
      this.workerLoading = true;

      var birthday = new Date(
        this.newWorkerFormData.birthdayYear,
        this.newWorkerFormData.birthdayMonth - 1,
        this.newWorkerFormData.birthdayDay
      );

      this.$http({
        method: "post",
        url: "worker",
        data: {
          fullName: this.newWorkerFormData.fullName,
          emailAddress: this.newWorkerFormData.emailAddress.trim(),
          streetName: this.newWorkerFormData.streetName,
          houseNumber: this.newWorkerFormData.houseNumber,
          zipCode: this.newWorkerFormData.zipCode,
          cityName: this.newWorkerFormData.cityName,
          phoneNumber: this.newWorkerFormData.phoneNumber,
          birthday: birthday.getTime(),
          gender: this.newWorkerFormData.gender,
          org: this.organisationId,
          identityNumber: this.newWorkerFormData.identityNumber,
        },
      })
        .then((res) => {
          this.$emit("newworker", res.data);
          this.$refs.newWorkerForm.reset();
          this.workerLoading = false;
        })
        .catch((err) => {
          this.snackbarText = "Fehler bei Abfrage";
          this.snackbar = true;
          this.workerLoading = false;
          console.log("AXIOS ERROR: ", err);
        });
    },
  },
};
</script>
